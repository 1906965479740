import React                            from 'react';
import {render, unmountComponentAtNode} from 'react-dom';
import Loader                           from './Loader';
/**
 * Render Loader in DOM
 * @param type
 */
const showLoader = (type, fullscreen = true) => {
    const props       = {};
	const containerId = 'logo-loader';
	let container     = document.getElementById(containerId);

	if (container) {
		container.parentNode.removeChild(container);
	}

	// init DOM container
	container    = document.createElement('div');
	container.id = containerId;

	document.body.appendChild(container);

	// default props
	const closeFn = () => {
		if (document.getElementById(containerId)) {
			unmountComponentAtNode(document.getElementById(containerId));
		}
	};
	props.onClose = closeFn;

	render(<Loader {...props} type={type} fullscreen={fullscreen} />, container);
};


export {
	showLoader,
};
