'use strict';
/* globals VXConfig */

import Flux from '../flux/Flux';
import {setCookie} from "./CommonUtils";

class WebmasterCookieHelper {

	static setWebmasterCookiesOnUserInteraction() {
		if (VXConfig.wmId || VXConfig.wmSubref) {
			document.addEventListener('click', function onClickAnywhere() {
				setCookie(Flux.Constants.CookieNames.WM_ID, VXConfig.wmId, 365, '/', '', true, Flux.Constants.CookieSameSiteAttributes.LAX);
				setCookie(Flux.Constants.CookieNames.WM_SUBREF, VXConfig.wmSubref, 365, '/', '', true, Flux.Constants.CookieSameSiteAttributes.LAX);
				if (VXConfig.wmToken) {
					setCookie(Flux.Constants.CookieNames.WM_TOKEN, VXConfig.wmToken, 365, '/', '', true, Flux.Constants.CookieSameSiteAttributes.LAX);
				}

				document.removeEventListener('click', onClickAnywhere, true);
			}, true);
		}
	}
}

export default WebmasterCookieHelper;
