/* global VXConfig */
import React, {useState, useEffect}      from "react";
import PropTypes                         from 'prop-types';
import { getCountdownTillDate }          from "../Helper/CountdownHelper";

import style                             from './FifteenMinutesPreviewCountdown.module.less';
import ReloadHelper                      from "../../utils/ReloadHelper";
import UrlBuilder                        from "../../utils/UrlBuilder";
import Translations                      from "../../utils/Translations";
import { isFifteenMinutesPreviewActive } from "./FifteenMinutesPreviewHelper";
import Flux                              from "../../flux/Flux";

let timer = null;

function FifteenMinutesPreviewCountdown({onEnd}) {
    if (!isFifteenMinutesPreviewActive()) {
        return null;
    }

    const [countdown, setCountdown] = useState(null);
    const endTime = VXConfig.guest.esFreePreview.timerEnd;
    const end     = new Date(endTime * 1000);

    useEffect(() => {
        if (!timer && !countdown) {
            timer = window.setInterval(() => {
                const {mins, sec, isDone} = getCountdownTillDate(end);

                if (isDone) {
                    onEnd && onEnd();
                    Flux.Guest.fifteenMinutesPreviewCountdownChange(isDone);
                } else {
                    setCountdown({mins, sec, isDone});
                }
            }, 1000);
        }
        return () => {
            timer && window.clearInterval(timer);
        };
    }, []);

    const goToCams = () => {
        const params = new URLSearchParams();
        params.set("filter", JSON.stringify({livePreview: "LIVE"}));
		const urlString = UrlBuilder.buildCamsRoute() + '?' + params.toString();
        ReloadHelper.reload(urlString);
    };

    if (!countdown) {
        return null;
    }

    return <div className={style.countdownWrapper} >
        <div className={style.countdown}>
            <img src="/assets/img/modal/fifteen-minutes/clock.png" className={style.countdownIcon} />
            <div className={style.countdownText}>
                {Translations.get('fifteen-minutes-preview.countdown-text')}
                <div className={style.countdownTextTime}>
                    {countdown.mins}:{countdown.sec}
                </div>
            </div>
        </div>
        <div className={style.countdownLine}></div>
        <div className={style.countdownButton} onClick={goToCams}>
                {Translations.get('fifteen-minutes-preview.countdown-button')}
                <i className="icon -icon-single-arrow-right-line"></i>
        </div>
    </div>;
}

export default FifteenMinutesPreviewCountdown;

FifteenMinutesPreviewCountdown.propTypes = {
    onEnd: PropTypes.func,
};
